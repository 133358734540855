import {isPlatform} from '@ionic/react';
import {getMedia} from '../utils/mq';

export enum AppPlatform {
  BASIC = 'BASIC',
  IONIC = 'IONIC',
}

/**
 * Deals with setting and checking the platform
 */
export default class PlatformService {
  async getPlatform(): Promise<AppPlatform> {
    const isIonicBuild = process.env.IONIC_BUILD === '1';

    if (isIonicBuild) {
      return AppPlatform.IONIC;
    }

    const mq = getMedia();
    if (mq.SM_AND_DOWN) {
      return AppPlatform.IONIC;
    }

    return AppPlatform.BASIC;
  }

  public static isNativeApp(): boolean {
    return process.env.IONIC_BUILD === '1';
  }

  public static runtime(): 'ios' | 'android' | 'web' {
    if (this.isNativeApp()) {
      if (isPlatform('ios')) {
        return 'ios';
      } else {
        return 'android';
      }
    }

    return 'web';
  }

  public static isMobileWeb(): boolean {
    return isPlatform('mobileweb');
  }
}
