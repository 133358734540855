import {ipInfo, ready} from 'shared/utils';
import 'stylesheets/components/shared-switch.scss';
import $ from 'jquery';
import notificationTemplate from './notificationTemplate.ejs';
import cookie from 'cookie';
import footerTemplate from './footerTemplate.ejs';
import modalTemplate from './modalTemplate.ejs';
import {loadAnalyticsIfAllowed, showCookiePreferencesFooter} from './helpers';
import PlatformService from '../../src/services/PlatformService';

const ACCEPT_ALL_PREFERENCE = {
  performance: true,
  advertising: true,
};

let trackingPreference = null;
let currentPreferences = null;

try {
  currentPreferences = JSON.parse(cookie.parse(document.cookie).preference);
} catch (e) {
  // fails when cookie not present
}

trackingPreference = currentPreferences || ACCEPT_ALL_PREFERENCE;

loadAnalyticsIfAllowed(currentPreferences); // TODO: Refactor this, so it doesn't get executed upon parsing

ready(() => {
  let notificationTimeout;

  const renderModalState = () => {
    $('.js-change-cookie-preference[data-setting-name=advertising]').toggleClass(
      'checked',
      trackingPreference.advertising
    );
    $('.js-change-cookie-preference[data-setting-name=performance]').toggleClass(
      'checked',
      trackingPreference.performance
    );

    $('.js-change-cookie-preference.checked')
      .prev('.outer-switch-label')
      .text('Active')
      .removeClass('inactive');
    $('.js-change-cookie-preference:not(.checked)')
      .prev('.outer-switch-label')
      .text('Inactive')
      .addClass('inactive');
  };

  const renderNotification = () => {
    const $notification = $('.cookie-settings-notification');

    $notification.html(notificationTemplate());
    $notification.on('click', '.shared-notifications-dismiss-notification-button', function () {
      $(this).parents('.shared-toast').remove();
    });

    clearTimeout(notificationTimeout);
    notificationTimeout = setTimeout(() => {
      $('.cookie-settings-notification .shared-toast').remove();
    }, 3000);
  };

  const updatePreferences = (showNotification = true) => {
    renderModalState();
    trackingPreference.timestamp = Date.now();
    currentPreferences = trackingPreference;

    document.cookie = cookie.serialize('preference', JSON.stringify(trackingPreference));

    loadAnalyticsIfAllowed();

    if (showNotification) {
      renderNotification();
    }
  };

  const bindChangePreference = () => {
    $('.js-change-cookie-preference').on('click', function () {
      const setting = $(this).data('settingName');

      trackingPreference[setting] = !trackingPreference[setting];
      updatePreferences();
    });

    $('#cookie-settings').on('hidden.bs.modal', () => {
      const showNotification = !currentPreferences;

      updatePreferences(showNotification);
      $('.cookie-preference-footer').remove();
    });
  };

  const bindAcceptAll = () => {
    const $banner = $('.cookie-preference-footer');

    $banner.find('.cookie-preference-footer-accept').on('click', () => {
      $banner.remove();
      trackingPreference = ACCEPT_ALL_PREFERENCE;
      updatePreferences();
    });
  };

  const renderFooterTemplate = () => {
    const platformService = new PlatformService();

    platformService.getPlatform().then((platform) => {
      if (platform !== 'IONIC') {
        $('body').append(footerTemplate());
        bindAcceptAll();
      }
    });
  };

  const renderModalTemplate = () => {
    $('body').append(modalTemplate());
    $('body').append('<div class="cookie-settings-notification shared-notifications"></div>');
    bindChangePreference();
    renderModalState();

    $('#cookie-settings').on('shown.bs.modal', () => {
      $('.cookie-preference-footer').remove();
      $('.js-dismiss-cookie-settings-modal').focus();
    });
  };

  const initializeGDPR = () => {
    const preferencesSet = cookie.parse(document.cookie).preference;

    if (!preferencesSet) {
      renderFooterTemplate();
      ipInfo().then(({inEU, inCalifornia}) => {
        if (inEU || inCalifornia) {
          showCookiePreferencesFooter();
        }
      });
    }
  };

  renderModalTemplate();

  initializeGDPR();
});
